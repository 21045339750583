<template>
  <div class="product-container">
    <!-- banner_cp -->
    <div class="banner_cp" style="background-color: #f4f6f8">
      <div class="margin width1480 hidden">
        <div class="l">
          <dl style="display: block">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/kaituozhe/%E5%BC%80%E6%8B%93%E8%80%85-%E9%80%8F%E6%98%8E-01.png"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/kaituozhe2.png"
            />
          </dl>

          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/www.yalijuda.com_1_c9T5dJbKBW.png"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/www.yalijuda.com_2_aPGz9TM2td.png"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/www.yalijuda.com_3_qdK0sxufB3.png"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/www.yalijuda.com_4_mVAduXHp9h.png"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/www.yalijuda.com_5_f60CUr8YOk.png"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/www.yalijuda.com_6_jwqzIp_crd.png"
            />
          </dl>
          <dl>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/%E9%BE%99%E5%B9%B4%E8%81%9A%E8%B4%A2%E9%9D%A2%E6%9D%BF-s.png"
            />
          </dl>
        </div>
        <div class="r" v-if="'Web' == state.appType">
          <div class="bg a-fdB">
            <dd>开 拓 者</dd>
            <dl>全新智能交互 | 亲友共享充电 | 个性面板定制</dl>
            <ol class="f">
              <dt class="o">
                <span><i style="background-color: #ffffff"></i></span>
                <p>白色</p>
              </dt>
              <dt>
                <span><i style="background-color: #000000"></i></span>
                <p>黑色</p>
              </dt>
              <div class="height-16"></div>
              <dt class="o">
                <span><i style="background-color: #ffffff"></i></span>
                <p>庄子白</p>
              </dt>
              <dt>
                <span><i style="background-color: #000000"></i></span>
                <p>墨子黑</p>
              </dt>
              <dt class="o">
                <span><i style="background-color: #e6a30b"></i></span>
                <p>宝盖流苏</p>
              </dt>
              <dt>
                <span><i style="background-color: #50b7c8"></i></span>
                <p>思路</p>
              </dt>

              <dt class="o">
                <span><i style="background-color: #fccf18"></i></span>
                <p>财路</p>
              </dt>
              <dt>
                <span><i style="background-color: #1a49bc"></i></span>
                <p>此生必驾</p>
              </dt>
              <dt>
                <span><i style="background-color: #f4d923"></i></span>
                <p>龙年聚财</p>
              </dt>
            </ol>
            <div class="kaituozhe-flex-div">
              <a @click="$U.openProductFile('kaituozhe')">产品手册</a>
              <a class="movie-view-a" @click="viewKaituozheVideo()">观看视频</a>
            </div>
          </div>
        </div>

        <div class="r r-ktz" v-show="'Web' !== state.appType">
          <div class="bg a-fdB">
            <dd>开 拓 者</dd>
            <dl>全新智能交互 | 亲友共享充电 | 个性面板定制</dl>
            <ol class="f h5-ktz-ol">
              <dt class="o">
                <span><i style="background-color: #ffffff"></i></span>
                <p>白色</p>
              </dt>
              <dt>
                <span><i style="background-color: #000000"></i></span>
                <p>黑色</p>
              </dt>

              <dt class="o">
                <span><i style="background-color: #ffffff"></i></span>
                <p>庄子白</p>
              </dt>
              <div class="height-16"></div>
              <dt>
                <span><i style="background-color: #000000"></i></span>
                <p>墨子黑</p>
              </dt>
              <dt class="o">
                <span><i style="background-color: #e6a30b"></i></span>
                <p>宝盖流苏</p>
              </dt>
              <dt>
                <span><i style="background-color: #50b7c8"></i></span>
                <p>思路</p>
              </dt>
              <div class="height-16"></div>

              <dt class="o">
                <span><i style="background-color: #fccf18"></i></span>
                <p>财路</p>
              </dt>
              <dt>
                <span><i style="background-color: #1a49bc"></i></span>
                <p>此生必驾</p>
              </dt>
              <dt>
                <span><i style="background-color: #f4d923"></i></span>
                <p>龙年聚财</p>
              </dt>
              <div class="height-16"></div>
            </ol>
            <div class="kaituozhe-flex-div">
              <a @click="$U.openProductFile('kaituozhe')">产品手册</a>
              <a class="movie-view-a" @click="viewKaituozheVideo()">观看视频</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新世代家庭充电桩 -->
    <div id="k1">
      <div
        class="ktz_cdz_bg"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_cdz_bg.jpg);
        "
      >
        <div class="margin width1480 hidden ktz_cdz">
          <div class="tit">
            <dd>新世代家庭充电桩</dd>
            <dl>开拓者数字交流桩</dl>
          </div>
          <div class="img">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_cdz.png"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 新世代家庭充电桩 -->
    <div id="kn1">
      <img
        class="k1-2-img"
        src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaituozhe/7%E4%B8%AA%E9%9D%A2%E6%9D%BF-05.png"
      />
    </div>

    <!-- APP智控 实时掌握充电状态 -->
    <div id="k2">
      <div
        class="ktz_appzk_bg"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_appzk_bg.jpg);
        "
      >
        <div class="margin width1480 hidden ktz_appzk">
          <div class="tit">
            <dd>APP智控 实时掌握充电状态</dd>
            <dl>远程启充，监控充电数据</dl>
          </div>
        </div>
      </div>
    </div>
    <!-- cp_aqfh -->
    <div id="k3">
      <div class="margin width1480 hidden">
        <ul class="cp_aqfh">
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh1.png"
                />
              </ol>
              <dd>过压/欠压保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh2.png"
                />
              </ol>
              <dd>过流保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh3.png"
                />
              </ol>
              <dd>雷电保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh4.png"
                />
              </ol>
              <dd>接地保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh5.png"
                />
              </ol>
              <dd>防漏电保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh6.png"
                />
              </ol>
              <dd>短路保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh13.png"
                />
              </ol>
              <dd>环境电源异常数据记录</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/cp_aqfh8.png"
                />
              </ol>
              <dd>IP55</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh9.png"
                />
              </ol>
              <dd>过温保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh10.png"
                />
              </ol>
              <dd>预约充电</dd>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 蓝牙充电 不被信号困扰 -->
    <div id="k4">
      <div
        class="ktz_lycd_bg"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_lycd_bg.jpg);
        "
      >
        <div class="margin width1480 hidden ktz_lycd">
          <div class="tit">
            <dd>蓝牙充电 不被信号困扰</dd>
            <dl>无wifi/4G时也能预约和充电</dl>
          </div>
          <div class="img">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_lycd.png"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 更懂你的充电枪 -->
    <div id="k5">
      <div class="ktz_cdq_tit">
        <dd>更懂你的充电枪</dd>
        <dl>体验升级</dl>
      </div>
    </div>
    <!-- 便于单手握紧 -->
    <div id="k6">
      <div
        class="ktz_bydsjw"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_bydsjw.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="l">
            <dd>便于单手握紧</dd>
            <dl>符合人体工学的纹理设计</dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 距离远也能充 -->
    <div id="k7">
      <div
        class="ktz_jlyync"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_jlyync_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_jlyync.png"
            class="l"
          />
          <div class="r">
            <dd>距离远也能充</dd>
            <dl>
              <p>6米长枪线，柔软的TPU材质</p>
              <p>使用收纳更轻便</p>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 2种功率规格选择 -->
    <div id="k8">
      <div
        class="ktz_lzglggxz"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_lzglggxz_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="l">
            <dd>2种功率规格选择</dd>
            <dl>
              <span>220V / 7KW</span>
              <span>380v/ 21KW</span>
            </dl>
          </div>
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_lzglggxz.png"
            class="r"
          />
        </div>
      </div>
    </div>
    <!-- 顶部ICON灯升级，充电状态更醒目 -->
    <div id="k9">
      <div class="ktz_icond">
        <div class="margin width1480 hidden">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_icond.png"
            class="l"
          />
          <div class="r">
            <dd>顶部ICON灯升级，充电状态更醒目</dd>
            <dl>
              <p>产品面板可DIY，打造自己独一</p>
              <p>无二的充电桩</p>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 10重保障 户外安心用 -->
    <div id="k10">
      <div
        class="cp_f_bg ktz_f_bg"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/ktz_f_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd>10重保障 户外安心用</dd>
            <dl>IP55防护等级 轻松应对恶劣天气</dl>
            <ol>
              <a @click="$U.openProductFile('kaituozhe')"
                >产品手册<i class="fa fa-angle-right"></i
              ></a>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="template-full-mask-div" v-if="state.showFullVideoFlag">
      <div class="center-view-view-div">
        <video
          controls
          name="media"
          loop="loop"
          controlsList="nodownload"
          id="myvideo"
          class="ktz_video_style"
        >
          <source
            src="https://img.cdn.wxzhida.cn/shzd-web-site/mp4/v1/%E6%8C%9A%E8%BE%BE%E5%BC%80%E6%8B%93%E8%80%85%E6%95%B0%E5%AD%97%E5%AE%B6%E5%85%85%E6%A1%A9.MP4"
            type="video/mp4"
          />
        </video>
      </div>

      <div
        class="fixed-close-icon-div"
        @click="state.showFullVideoFlag = false"
      >
        <i class="fa fa-close"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
export default {
  name: "About",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();

    const viewKaituozheVideo = () => {
      state.showFullVideoFlag = true;
    };

    const state = reactive({
      showFullVideoFlag: false,
      appType: "Web",
    });

    onMounted(() => {
      document.title = "开拓者";

      // 顶部
      window.scrollTo(0, 0);

      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //安卓端
      var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //IOS端
      const innerWidth = window.innerWidth;
      state.appType = "Web";
      if (isAndroid && innerWidth < 450) {
        state.appType = "Android";
      }
      if (isIos && innerWidth < 450) {
        state.appType = "iOS";
      }

      $(".popupbox_i").click(function () {
        $(this).hide();
        $("#myvideo")[0].pause();
      });

      $(".bf").click(function () {
        $(this).hide();
        $("#myvideo")[0].play();
      });

      $(".banner_cp .width1480 .r ol.f dt").click(function () {
        var liindex = $(".banner_cp .width1480 .r ol.f dt").index(this);
        $(this).addClass("o").siblings().removeClass("o");
        $(".banner_cp .width1480 .l dl")
          .eq(liindex)
          .fadeIn(150)
          .siblings("dl")
          .hide();
      });

      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        console.log(wst);
        for (var i = 1; i < 20; i++) {
          if ($("#k" + i) && $("#k" + i).offset()) {
            //加循环
            if ($("#k" + i).offset().top <= wst + 60) {
              //判断滚动条位置
              $("#k" + i).addClass("on"); //给当前导航加c类

              if (i == 9) {
                $("#k10").addClass("on"); //给当前导航加c类
              }
            } else {
            }
          }
        }
      });
    });
    return {
      state,
      viewKaituozheVideo,
    };
  },
};
</script>

<style lang="less" scoped>
.ktz_video_style {
  width: 100%;
}

.video-bg-dl {
  padding-top: 150px;
}

.video-bg-dl a {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  color: #ffffff;
  display: flex;
  text-align: center;
}

.video-bg-dl ol {
  font-size: 1.5rem;
  position: relative;
  display: flex;
  width: 666px;
  justify-content: center;
  margin-top: 330px;
}

.video-bg-dl ol i {
  transition: all 0.5s ease;
  border: 2px solid #ffffff;
  position: absolute;
  width: 2.875rem;
  height: 3.375rem;
  line-height: 3.375rem;
  border-radius: 50%;
  font-weight: 300;
  padding-left: 0.5rem;
}

.kaituozhe-flex-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kaituozhe-flex-div a {
  margin: 2.5vh 20px 0px !important;
}

.kaituozhe-flex-div .movie-view-a {
  color: #364166 !important;
  background-color: #ffffff !important;
  border: 2px solid #364166 !important;
  font-weight: 600;
}

.template-full-mask-div {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000d4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10086;
}

.center-view-view-div {
  width: 80%;
  height: calc(80% - 88px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.kaituozhe_video_style {
  z-index: 10089;
  width: 100%;
  height: 100%;
}

.fixed-close-icon-div {
  position: fixed;
  right: 32px;
  top: 32px;
  color: #f2f2ff;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 400;
}

.k1-2-img {
  margin-bottom: -10px;
}

.height-16 {
  height: 16px;
}

.ktz-white-img {
  width: 580px;
  height: 580px;
  margin-top: 20px !important;
}

.h5-ktz-ol dt {
  width: 80px;
}
</style>
